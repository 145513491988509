import React, { useEffect, useState } from "react";
import { useContext } from "react";
import {
  useLocale,
  Product,
  CartContext,
  Carticon,
  GlobalConfig,
} from "@lilbit/shared";
import Link from "next/link";

type Props = {
  config: GlobalConfig;
  mainProduct: Product;
  textColor?: string;
  borderColor?: string;
  borderWidth?: number;
  backgroundColor?: string;
  buyBtnText?: string;
  cartIconColor?: string;
  siteName?: string;
};

const AddToCart = ({
  borderColor = "border-primary-2",
  borderWidth = 2,
  mainProduct,
  buyBtnText,
  config,
  siteName,
}: Props) => {
  const locale = useLocale().urlPrefix;
  const { addProduct } = useContext(CartContext);
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    setLoading(true);
    addProduct(mainProduct);
    setLoading(false);
  };

  if (siteName == "lilcat") {
    return (
      <Link
        href={{
          pathname:
            locale == "en"
              ? "https://www.elgiganten.se/product/mobiler-tablets-smartklockor/mobiltillbehor/gps-tracker-bluetooth-tracker/lildog-gps-tracker-for-katter/721935"
              : "https://www.elkjop.no/product/mobil-nettbrett-og-smartklokker/mobiltilbehor/gps-og-bluetooth-tracker/lilcat-gps-sporingsbrikke-for-katter/721935",
        }}
      >
        <button
          className={`flex bg-primary-mono-2 justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow lg:mx-0 border-${borderWidth} rounded-xl h-[48px] w-full focus:outline-none focus:ring-2 ${borderColor}`}
          onClick={handleClick}
          disabled={loading}
        >
          <div className="flex items-center justify-center w-full space-x-4 px-2 md:px-4">
            <p
              className={`text-p-md leading-6 font-semibold text-center text-primary-mono-4`}
            >
              {buyBtnText || ""}
            </p>
            <Carticon height={23} width={21} color={"#ebffc7"} />
          </div>
        </button>
      </Link>
    );
  }

  return (
    <Link
      href={{
        pathname: "/[lang]/[category]",
      }}
      as={`/${locale}/${config?.checkoutSlug?.current || "checkout"}`}
    >
      <button
        className={`flex bg-primary-mono-2 justify-center items-center font-objektiv cursor-pointer transform transition-colors button-shadow lg:mx-0 border-${borderWidth} rounded-xl h-[48px] w-full focus:outline-none focus:ring-2 ${borderColor}`}
        onClick={handleClick}
        disabled={loading}
      >
        <div className="flex items-center justify-center w-full space-x-4 px-2 md:px-4">
          <p
            className={`text-p-md leading-6 font-semibold text-center text-primary-mono-4`}
          >
            {buyBtnText || ""}
          </p>
          <Carticon height={23} width={21} color={"#ebffc7"} />
        </div>
      </button>
    </Link>
  );
};

export default AddToCart;
