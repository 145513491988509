import { useContext, useEffect, useState, useRef } from "react";
import countryList from "./country-list";
import { useLocale } from "../../translations";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { PaymentIntent } from "@stripe/stripe-js";
import { Product } from "../../lib";
import { CartContext, CountryContext } from "../../context";
import Image from "next/image";
import appconfig from "../../lib/app-config";
import validateAndSanitizeCheckoutForm from "./validate-checkout";
import axios from "axios";
import { useRouter } from "next/router";
import { Image as ImageType } from "../../lib";
import AcceptTerms from "./acceptTerms";
import Breadcrumbs from "./breadcrumbs";
import PricingTable from "../pricingTable";

import Testimonial from "../Testimonial";
import validator from "validator";
import isEmpty from "./validate-isEmpty";
import { Transition, Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { XCircleIcon } from "@heroicons/react/20/solid";
import { SitenameContext } from "../../context/SitenameContext";
import FastCheckout from "../fastCheckout";
import ThirtyDayFreeTrial from "../thirtyDayFreeTrial";

const paymentMethods = [
  { id: "credit_card_payment" },
  { id: "vipps_express" },
  { id: "klarna_payment" },
];

const initialState = {
  firstName: "",
  lastName: "",
  // company: '',
  country: "",
  address1: "",
  address2: "",
  city: "",
  // state: '',
  postcode: "",
  phone: "",
  email: "",
  createAccount: false,
  orderNotes: "",
  paymentMethod: "stripe",
  acceptTerms: false,
  subscribeNews: true,
  errors: null,
  coupon: "",
  mainProductPrices: "",
};

export type CheckoutInput = {
  firstName: string;
  lastName: string;
  country: string;
  streetAddress: string;
  city: string;
  phoneNumber: string;
  email: string;
  postCode: string;
  siteName: "lildog" | "lilcat";
  mainProduct: Product;
  productTaxRate: number;
  subscribeNews: boolean;
  acceptTerms: boolean;
};

export type VippsCheckoutInput = {
  subscribeNews?: boolean;
  acceptTerms: boolean;
};

export interface Props {
  firstName: string;
  lastName: string;
  country: string;
  streetAddress: string;
  city: string;
  phoneNumber: string;
  email: string;
  postCode: string;
  reviewAuthor: string;
  reviewText: string;
  reviewRating: number;
  reviewImage: ImageType;
  siteName: "lildog" | "lilcat";
  mainProduct: Product;
  productTaxRate: number;
  urlFor: Function;
}

const CheckoutFormv2 = (props: Props) => {
  const {
    firstName,
    lastName,
    streetAddress,
    city,
    phoneNumber,
    email,
    postCode,
    siteName,
    mainProduct,
    productTaxRate,
    reviewAuthor,
    reviewText,
    reviewRating,
    reviewImage,
    urlFor,
  } = props;

  const [input, setInput] = useState(initialState);
  const [acceptedTerms, setAcceptedTerms] = useState(input?.acceptTerms);
  const [showFirstNameError, setShowFirstNameError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [showCountryError, setShowCountryError] = useState(false);
  const [showCityError, setShowCityError] = useState(false);
  const [showPostcodeError, setShowPostcodeError] = useState(false);
  const [showAddress1Error, setShowAddress1Error] = useState(false);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [tooltipBtn, setTooltipBtn] = useState(false);
  const [chosenPayment, setChosenPayment] = useState("credit_card_payment");
  const [strippedProduct, setStrippedProduct] = useState<any>([]);
  const [acceptedCodes, setAcceptedCodes] = useState([]);
  const [priceWithQuantityAndDiscount, setPriceWithQuantityAndDiscount] =
    useState(undefined);
  const [discount, setDiscount] = useState(undefined);
  const [creditLoading, setCreditLoading] = useState(false);
  const [creditcardErrorMessage, setCreditcardErrorMessage] = useState(null);
  const [showCreditcardError, setShowCreditcardError] = useState(false);
  const [formState, setFormState] = useState("waiting");
  const [currentStep, setCurrentStep] = useState(0);
  const [couponLoading, setCouponLoading] = useState(false);
  const [isCardInvalid, setIsCardInvalid] = useState(false);
  const { cookieConsentGiven } = useContext(SitenameContext);

  const codeRef = useRef(null);

  const router = useRouter();
  const { country } = useContext(CountryContext);
  const { nrOfMainProducts, setNrOfMainProducts, mainProductPrices } =
    useContext(CartContext);

  const stripe = useStripe();
  const elements = useElements();
  const urlPrefix = useLocale().urlPrefix;
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleOnChange = (event) => {
    if ("createAccount" === event.target.name) {
      const newState = { ...input, [event.target.name]: !input.createAccount };
      setInput(newState);
    } else {
      const newState = { ...input, [event.target.name]: event.target.value };
      setInput(newState);
    }
  };

  const handleSubFormPixelStartCheckout = () => {
    if (
      typeof window !== "undefined" &&
      window.ttq &&
      typeof window.ttq.track === "function"
    ) {
      const formData = {
        value: getTotalPrice(),
        currency: country.currency,
        contents: [
          {
            content_id: mainProduct.id,
            content_name: mainProduct.name,
            content_type: "product",
          },
        ],
      };
      window.ttq.track("InitiateCheckout", formData);
    }
    if (
      typeof window !== "undefined" &&
      window.snaptr &&
      typeof window.snaptr === "function"
    ) {
      const formData = {
        price: getTotalPrice(),
        currency: country.currency,
        item_ids: mainProduct.id,
        number_item: nrOfMainProducts,
      };
      window.snaptr("track", "START_CHECKOUT", formData);
    }
  };

  const getTax = () => {
    let price: number = 0;
    if (strippedProduct && discount) {
      price = priceWithQuantityAndDiscount * (productTaxRate / 100);
    } else if (strippedProduct) {
      price =
        (parseFloat(mainProductPrices?.PRICE[country.currency]) *
          nrOfMainProducts *
          productTaxRate) /
        100;
    }
    return price.toFixed(2);
  };

  const getTotalPrice = () => {
    let price: number = 0;
    if (strippedProduct && discount) {
      // Return (price of product minus price of coupons) + tax * (price of product minus price of coupons) in right currency
      price = priceWithQuantityAndDiscount * (1 + productTaxRate / 100);
    } else if (strippedProduct) {
      //Return price of products * tax
      price =
        parseFloat(mainProductPrices?.PRICE[country.currency]) *
          nrOfMainProducts +
        (parseFloat(mainProductPrices?.PRICE[country.currency]) *
          productTaxRate *
          nrOfMainProducts) /
          100;
    }
    return price.toFixed(2);
  };

  const getPartialSum = () => {
    let price: number = 0;
    if (strippedProduct && discount) {
      price = priceWithQuantityAndDiscount;
    } else if (strippedProduct) {
      price =
        parseFloat(mainProductPrices?.PRICE[country.currency]) *
        nrOfMainProducts;
    }
    return price.toFixed(2);
  };

  async function handleStripe(): Promise<PaymentIntent | Error> {
    // Guard against stripe or elements not being available
    if (!stripe || !elements) {
      setCreditLoading(false);
      throw Error(`stripe or elements undefined`);
    }

    // Extract the payment data from our <CardElement/> component
    const cardElements = elements.getElement(CardElement);

    //Show and hide error messages (I had to ignore ts-errors because of a bug in typescript https://github.com/stripe/stripe-js/issues/91#issuecomment-678328871)
    if (cardElements && stripe) {
      // @ts-ignore
      cardElements.on("focus", function (event) {
        setShowCreditcardError(false);
        setCreditcardErrorMessage("");
      });
      // @ts-ignore
      cardElements.on("blur", function (event) {
        setShowCreditcardError(true);
      });
      cardElements.on("change", function (event) {
        if (event.complete) {
          setCreditcardErrorMessage("");
        }
      });
    }

    // Guard against an undefined value
    if (!cardElements) {
      setCreditLoading(false);
      throw Error(`cardElements not found`);
    }

    const paymentMethodReq = await stripe.createPaymentMethod({
      type: "card",
      card: cardElements,
      billing_details: {
        address: {
          city: input.city,
          country: input.country,
          line1: input.address1,
          postal_code: input.postcode,
        },
        email: input.email,
        name: input.firstName + " " + input.lastName,
        phone: input.phone,
      },
    });

    if (paymentMethodReq.error) {
      setCreditcardErrorMessage(paymentMethodReq.error.message);
      setShowCreditcardError(true);
      setCreditLoading(false);
      return;
    }
    // TikTokPixel Initiates if there is no payment error
    if (
      typeof window !== "undefined" &&
      window.ttq &&
      typeof window.ttq.track === "function"
    ) {
      const formData = {
        contents: [
          {
            content_id: `${mainProduct.id}`,
            content_name: mainProduct.name,
          },
        ],
        value: getTotalPrice(),
        currency: country.currency,
        quantity: nrOfMainProducts,
        desription: "stripe",
      };
      window.ttq.track("CompletePayment", formData);
    }
    const { data: created_order } = await axios.post(
      "/api/payment/checkout_order",
      {
        order: input,
        products: [strippedProduct],
        coupon: acceptedCodes.length > 0 ? acceptedCodes[0].code : "",
        payment_method_title: "Credit Card (Stripe)",
        payment_method: "Stripe",
        shipping_method_id: country.countryCode === "NO" ? "Post_nord" : "DHL",
        shipping_method_title: country.countryCode ? "Post Nord" : "DHL",
        cvg_uid: document?.cookie?.match(/__cvg_uid=([^;]+)/)?.[1] || "",
      }
    );

    const { data: clientSecret } = await axios.post(
      "/api/payment/payment_intents",
      {
        created_order: created_order.id,
      }
    );
    const { error, paymentIntent } = await stripe.confirmCardPayment(
      clientSecret,
      {
        payment_method: paymentMethodReq.paymentMethod.id,
      }
    );

    if (error) {
      setCreditcardErrorMessage(error.message);
      setShowCreditcardError(true);
      setCreditLoading(false);
      return;
    }

    if ("status" in paymentIntent && paymentIntent.status === "succeeded") {
      await axios.put("/api/payment/checkout_order", {
        order_id: created_order.id,
        payment_intent_id: paymentIntent.id,
        payment_intent_currency: paymentIntent.currency,
      });
      await router.push({
        pathname: `/${urlPrefix}/order/${created_order?.id}`,
        query: { subscribe_to_newsletter: input.subscribeNews },
      });
      setFormState("succeeded");

      setCreditLoading(false);
    } else {
      setCreditLoading(false);
    }
    return paymentIntent;
  }

  async function handleSubmit(event: React.FormEvent) {
    setCreditLoading(true);
    event.preventDefault();
    try {
      const result = validateAndSanitizeCheckoutForm(input, urlPrefix, false); //This step is duplicated because we already check for errors in both credit-stepper and vipps-stepper
      if (!result.isValid) {
        setCreditLoading(false);
        setInput({ ...input, errors: result.errors });
        return;
      }

      await handleStripe();
    } catch (error) {
      setCreditLoading(false);
    }
  }

  const validateCoupon = async () => {
    setCouponLoading(true);
    //remove the coupon key from input errors to make sure we get a clean slate
    let input_errors = input.errors;
    delete input_errors["coupon"];
    setInput({ ...input, errors: input_errors });

    const { data: couponData } = await axios.post(
      "/api/payment/validate_coupon",
      {
        coupon: codeRef.current?.value,
      }
    );

    let errors = {};
    let sanitizedData = {};
    if (couponData === -3) {
      errors[
        "coupon"
      ] = `${translations.checkout_errors.could_not_check_coupon}`;
    } else if (couponData === 401) {
      errors[
        "coupon"
      ] = `${translations.checkout_errors.coupon_is_used[urlPrefix]}`;
    } else if (couponData === 400) {
      errors[
        "coupon"
      ] = `${translations.checkout_errors.coupon_does_not_exist[urlPrefix]}`;
    }
    if (!errors["coupon"]) {
      sanitizedData["coupon"] = validator.trim("code");
      sanitizedData["coupon"] = validator.escape(sanitizedData["coupon"]);
      setAcceptedCodes([
        {
          discountType: couponData.discount_type,
          code: codeRef.current?.value,
          valueUSD: couponData.meta_data?.find(
            (element) => element.key === "_usacoupon_amount"
          ).value,
          valueEuro: couponData.meta_data?.find(
            (element) => element.key === "_eurocoupon_amount"
          ).value,
          value: couponData.amount,
        },
      ]);
      // setCode("");
      codeRef.current.value = "";
    }
    if (!isEmpty(errors)) {
      setInput({ ...input, errors: errors });
    }
    setCouponLoading(false);
  };

  const handleSetInput = (props: any) => {
    setInput(props);
  };

  const handlePixelAddPaymentInfoStripe = () => {
    if (
      typeof window !== "undefined" &&
      window.ttq &&
      typeof window.ttq.track === "function"
    ) {
      const formData = {
        value: getTotalPrice(),
        currency: country.currency,
        description: "stripe",
        contents: [
          {
            content_id: mainProduct.id,
            content_name: mainProduct.name,
            content_type: "product",
          },
        ],
      };
      window.ttq.track("AddPaymentInfo", formData);
    }
    if (
      typeof window !== "undefined" &&
      window.snaptr &&
      typeof window.snaptr === "function"
    ) {
      const formData = {
        price: getTotalPrice(),
        currency: country.currency,
        item_ids: mainProduct.id,
        description: "stripe",
        number_item: nrOfMainProducts,
      };
      window.snaptr("track", "ADD_BILLING", formData);
    }
  };

  useEffect(() => {
    if (acceptedCodes[0]) {
      if (acceptedCodes[0].discountType === "fixed_cart") {
        setDiscount(
          parseFloat(
            country.currency === "USD"
              ? acceptedCodes[0].valueUSD
              : country.currency === "EUR"
              ? acceptedCodes[0].valueEuro
              : acceptedCodes[0].value
          ).toFixed(2)
        );
        setPriceWithQuantityAndDiscount(
          mainProductPrices?.PRICE[country.currency] * nrOfMainProducts -
            discount
        );
      } else if (acceptedCodes[0].discountType === "fixed_product") {
        setDiscount(
          (country.currency === "USD"
            ? acceptedCodes[0].valueUSD * nrOfMainProducts
            : country.currency === "EUR"
            ? acceptedCodes[0].valueEuro * nrOfMainProducts
            : acceptedCodes[0].value * nrOfMainProducts
          ).toFixed(2)
        );
        setPriceWithQuantityAndDiscount(
          mainProductPrices?.PRICE[country.currency] * nrOfMainProducts -
            discount
        );
      } else {
        // discountType === "percent"
        setDiscount(
          parseFloat(
            country.currency === "USD"
              ? acceptedCodes[0].valueUSD
              : country.currency === "EUR"
              ? acceptedCodes[0].valueEuro
              : acceptedCodes[0].value
          ).toFixed(2)
        );
        setPriceWithQuantityAndDiscount(
          (mainProductPrices?.PRICE[country.currency] - discount) *
            nrOfMainProducts
        );
      }
    }
  }, [
    acceptedCodes,
    mainProductPrices,
    nrOfMainProducts,
    discount,
    country.currency,
  ]);

  useEffect(() => {
    setStrippedProduct({
      id: mainProduct.id,
      tax_class: mainProduct.tax_class,
      tax_status: mainProduct.tax_status,
      quantity: nrOfMainProducts,
      image: mainProduct?.images[0].src,
      euro_price: mainProduct.meta_data.filter(
        (price) => price.key === "_euro_price"
      )[0].value,
      usd_price: mainProduct.meta_data.filter(
        (price) => price.key === "_usa_price"
      )[0].value,
      nok_price: mainProduct.price,
      name: mainProduct.name,
    });
  }, [nrOfMainProducts, mainProduct]);
  // tik Tok pixel
  /* Track event when user starts checkout */
  useEffect(() => {
    if (typeof window.cvg === "function") {
      {
        //@ts-ignore to ignore the error that cvg is not defined
        cvg({
          method: "track",
          eventName: "Started Checkout",
          properties: {
            total_price: getTotalPrice(),
            currency: country.currency,
            items: [
              {
                product_id: mainProduct.id, // Woocommerce product ID
                sku: mainProduct.sku,
                name: mainProduct.name,
                price: mainProductPrices?.PRICE[country.currency],
                currency: country.currency,
                quantity: nrOfMainProducts,
              },
            ],
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mainProduct,
    mainProductPrices,
    nrOfMainProducts,
    country.currency,
    cookieConsentGiven,
  ]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.ttq &&
      typeof window.ttq.track === "function"
    ) {
      const formData = {
        value: getTotalPrice(),
        currency: country.currency,
        contents: [
          {
            content_id: mainProduct.id,
            content_name: mainProduct.name,
            content_type: "product",
          },
        ],
      };
      window.ttq.track("AddToCart", formData);
    }
    if (
      typeof window !== "undefined" &&
      window.snaptr &&
      typeof window.snaptr === "function"
    ) {
      const formData = {
        price: getTotalPrice(),
        currency: country.currency,
        item_ids: mainProduct.id,
        number_item: nrOfMainProducts,
      };
      window.snaptr("track", "ADD_CART", formData);
    }
    if (
      typeof window !== "undefined" &&
      window.klaviyo &&
      typeof window._learnq.push === "function"
    ) {
      window.klaviyo.push([
        "track",
        "Started Checkout",
        {
          product_id: mainProduct.id,
          country_code: urlPrefix,
        },
      ]);
      // window._learnq.push(["track", "View Prod", {}]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const result = validateAndSanitizeCheckoutForm(input, urlPrefix, false);
  useEffect(() => {
    if (
      input?.firstName?.length > 0 &&
      input?.lastName?.length > 0 &&
      input?.email?.length > 0 &&
      input?.phone?.length > 0 &&
      input?.country?.length > 0 &&
      input?.postcode?.length > 0 &&
      input?.address1?.length > 0 &&
      input?.city?.length > 0 &&
      result.isValid
    ) {
      handleSetInput({ ...input, errors: result.errors });
      setNextDisabled(false);
    } else {
      handleSetInput({ ...input, errors: result.errors });
      setNextDisabled(true);
    }
    // removed input as dependecy because of infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    urlPrefix,
    input?.firstName?.length,
    input?.lastName?.length,
    input?.email?.length,
    input?.phone?.length,
    input?.country?.length,
    input?.postcode?.length,
    input?.address1?.length,
    input?.city?.length,
  ]);

  useEffect(() => {
    if (tooltipBtn) {
      setTimeout(() => {
        setTooltipBtn(false);
      }, 2000);
    }
  }, [tooltipBtn]);

  //scroll to top when changing step
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    setIsCardInvalid(true);
    setAcceptedTerms(false);
    if (document) {
      const card = document.getElementById("stripe-card-element");
      if (card) {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            if (
              // @ts-ignore
              mutation.target.classList.contains("StripeElement--empty") ||
              // @ts-ignore
              mutation.target.classList.contains("StripeElement--invalid")
            ) {
              setIsCardInvalid(true);
            } else {
              setIsCardInvalid(false);
            }
          });
        });
        observer.observe(card, {
          attributes: true,
          attributeFilter: ["class"],
        });
      }
    }
  }, [currentStep, chosenPayment]);

  return (
    <div className="bg-gray-50 font-objektiv min-h-screen lg:pt-12">
      {/* Order summary mobile */}
      <Disclosure>
        {({ open }) => (
          <div className="w-full">
            <Disclosure.Button className="flex w-full items-center py-6 font-medium px-4 max-w-2xl mx-auto sm:px-6 lg:hidden">
              <span className="mr-auto text-base">
                {translations.total[urlPrefix]}
              </span>
              <span className="mr-2 text-base">
                {discount
                  ? (country.currency === "NOK" ? "kr" : country.currency) +
                    " " +
                    getTotalPrice() +
                    ` ${country.currency}`
                  : getTotalPrice() + ` ${country.currency}`}
              </span>

              <ChevronDownIcon
                className={`h-5 w-5 text-gray-500 ${
                  open ? "rotate-180 transform" : ""
                }`}
                aria-hidden="true"
              />
            </Disclosure.Button>
            <Transition
              enter="transition duration-200 ease-out"
              enterFrom="transform scale-95 opacity-0"
              enterTo="transform scale-100 opacity-100"
              leave="transition duration-200 ease-out"
              leaveFrom="transform scale-100 opacity-100"
              leaveTo="transform scale-95 opacity-0"
            >
              <Disclosure.Panel className="relative py-6 z-20 max-w-2xl px-4 mx-auto">
                <dl className="mx-auto space-y-6">
                  {acceptedCodes.length > 0 && (
                    <div className="flex flex-col justify-center">
                      {acceptedCodes.map((c, i) => (
                        <div
                          key={c.code + i}
                          className="flex items-center justify-between"
                        >
                          <dt className="text-sm">{c.code}</dt>
                          <dd className="text-sm font-medium text-gray-900">
                            {`${country.currency} -${discount} ${
                              appconfig.currencySymbols[country.currency]
                            }`}
                          </dd>
                        </div>
                      ))}
                    </div>
                  )}
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">
                      {translations.subtotal[urlPrefix]}
                    </dt>
                    <dd className="text-sm font-medium text-gray-900">
                      {country.currency === "NOK" ? "kr" : country.currency}{" "}
                      {getPartialSum()}
                      {appconfig.currencySymbols[country.currency]}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">
                      {translations.shipping[urlPrefix]}
                    </dt>
                    <dd className="text-sm font-medium text-gray-900">
                      {"  "}
                      {country.currency === "NOK"
                        ? "kr"
                        : country.currency} 0{" "}
                      {appconfig.currencySymbols[country.currency]}
                    </dd>
                  </div>
                  <div className="flex items-center justify-between">
                    <dt className="text-sm">{translations.taxes[urlPrefix]}</dt>
                    <dd className="text-sm font-medium text-gray-900">
                      {country.currency === "NOK" ? "kr" : country.currency}{" "}
                      {getTax()} {appconfig.currencySymbols[country.currency]}
                    </dd>
                  </div>
                  <div className="flex items-start justify-end">
                    <div className="ml-4">
                      <label htmlFor="quantity" className="sr-only">
                        Quantity
                      </label>
                      {/* array with nrs from 1 to 10 and map over it */}
                      <select
                        id="quantity"
                        name="quantity"
                        onChange={(e) =>
                          setNrOfMainProducts(parseInt(e.target.value))
                        }
                        className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                      >
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((nr) => {
                          return (
                            <option key={nr * 3.14} value={nr}>
                              {nr}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                  <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                    <dt className="text-base font-medium">
                      {translations.total[urlPrefix]}
                    </dt>
                    <dd className="text-base font-medium text-gray-900">
                      {discount
                        ? (country.currency === "NOK"
                            ? "kr"
                            : country.currency) +
                          " " +
                          getTotalPrice() +
                          ` ${country.currency}`
                        : getTotalPrice() + ` ${country.currency}`}
                    </dd>
                  </div>
                  <div className="mt-1">
                    <label
                      htmlFor="discount-code-mobile"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {translations.enter_code[urlPrefix]}
                    </label>
                    <div className="mt-1 flex relative">
                      {input?.errors?.hasOwnProperty("coupon") && (
                        <div>
                          <p className="text-sm text-red-500 absolute -bottom-6">
                            {input?.errors?.coupon}
                          </p>
                        </div>
                      )}
                      <input
                        // onChange={(e) => setCode(e.target.value)}
                        ref={codeRef}
                        type="text"
                        name="coupon"
                        id="coupon"
                        className={`mr-4 block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm
                        ${
                          input?.errors?.hasOwnProperty("coupon")
                            ? "border-bubblegum"
                            : "border-gray-300"
                        }
                        `}
                      />
                      <button
                        onClick={async (e) => {
                          await validateCoupon();
                        }}
                        className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-mono-3 focus:ring-offset-2 focus:ring-offset-gray-50"
                      >
                        <span className="flex space-x-2 items-center">
                          {translations.apply[urlPrefix]}
                          {couponLoading && (
                            <AiOutlineLoading3Quarters className="animate-spin inline-block ml-2" />
                          )}
                        </span>
                      </button>
                    </div>
                  </div>
                </dl>
              </Disclosure.Panel>
            </Transition>
          </div>
        )}
      </Disclosure>
      <div className="w-96"></div>
      <div className="mx-auto max-w-2xl px-4 pb-24 pt-2 relative sm:px-6 lg:max-w-7xl lg:px-8">
        <h2 className="sr-only">Checkout</h2>
        <form
          className="z-10 lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16"
          onSubmit={handleSubmit}
        >
          {currentStep === 0 && (
            <div className="relative ">
              <div className="space-y-4 flex flex-col">
                <div className="justify-start mb-2">
                  <FastCheckout
                    getTotalPrice={getTotalPrice}
                    product={mainProduct}
                    coupon={acceptedCodes.length > 0 ? acceptedCodes[0] : ""}
                    productTaxRate={productTaxRate}
                    country={country}
                    input={input}
                    nrMainProducts={nrOfMainProducts}
                  />
                </div>
                <div className="justify-end">
                  <Breadcrumbs
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                  />
                </div>
              </div>
              <div>
                <h2 className="text-lg font-medium text-gray-900">
                  {translations.your_details[urlPrefix]}
                </h2>
                <div className="mt-4">
                  <label
                    htmlFor="email-address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {email}
                  </label>
                  <div className="mt-1">
                    <input
                      onChange={handleOnChange}
                      value={input.email}
                      type="email"
                      id="email-address"
                      name="email"
                      autoComplete="email"
                      className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                        input?.errors?.hasOwnProperty("email") && showEmailError
                          ? "errorInput"
                          : "border-gray-300"
                      }`}
                      onBlur={() => setShowEmailError(true)}
                      onFocus={() => setShowEmailError(false)}
                    />
                  </div>
                  <div className="flex items-center pt-4">
                    <input
                      id="accept-terms"
                      name="accept-terms"
                      type="checkbox"
                      checked={input.subscribeNews}
                      onChange={(e) => {
                        setInput({
                          ...input,
                          subscribeNews: e.target.checked,
                        });
                      }}
                      className="h-4 w-4 rounded border-gray-300 text-primary-mono-2 focus:ring-primary-mono-3"
                    />
                    <div className="ml-2">
                      <label
                        htmlFor="accept-terms"
                        className="text-sm font-medium text-primary-mono-1 font-objektiv"
                      >
                        {translations.subscribe[urlPrefix]}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-6 border-t border-gray-200">
                <h2 className="text-lg font-medium text-gray-900">
                  {translations.shipping_address[urlPrefix]}
                </h2>
                <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                  <div>
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {firstName}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleOnChange}
                        value={input.firstName}
                        type="text"
                        id="first-name"
                        name="firstName"
                        autoComplete="given-name"
                        className={`block w-full rounded-md shadow-sm focus:border-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("firstName") &&
                          showFirstNameError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowFirstNameError(true)}
                        onFocus={() => setShowFirstNameError(false)}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {lastName}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleOnChange}
                        value={input.lastName}
                        type="text"
                        id="last-name"
                        name="lastName"
                        autoComplete="family-name"
                        className={`block w-full rounded-md shadow-sm focus:border-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("lastName") &&
                          showLastNameError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowLastNameError(true)}
                        onFocus={() => setShowLastNameError(false)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-2">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {streetAddress}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleOnChange}
                        value={input.address1}
                        required
                        type="text"
                        name="address1"
                        id="address"
                        autoComplete="street-address"
                        className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("address1") &&
                          showAddress1Error
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowAddress1Error(true)}
                        onFocus={() => setShowAddress1Error(false)}
                      />
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {city}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleOnChange}
                        value={input.city}
                        required
                        type="text"
                        name="city"
                        id="city"
                        autoComplete="address-level2"
                        className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("city") && showCityError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowCityError(true)}
                        onFocus={() => setShowCityError(false)}
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {props.country}
                    </label>
                    <div className="mt-1">
                      <select
                        required
                        onChange={handleOnChange}
                        defaultValue={input.country}
                        id="country-select"
                        name="country"
                        autoComplete="country-name"
                        className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("country") &&
                          showCountryError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowCountryError(true)}
                        onFocus={() => setShowCountryError(false)}
                      >
                        <option /> {/* Empty option needed */}
                        {countryList.map((country) => (
                          <option
                            key={country.countryCode}
                            value={country.countryCode}
                          >
                            {country.countryName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="postal-code"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {postCode}
                    </label>
                    <div className="mt-1">
                      <input
                        required
                        onChange={handleOnChange}
                        value={input.postcode}
                        type="text"
                        name="postcode"
                        id="postal-code"
                        autoComplete="postal-code"
                        className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("postcode") &&
                          showPostcodeError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowPostcodeError(true)}
                        onFocus={() => setShowPostcodeError(false)}
                      />
                    </div>
                  </div>

                  <div className="sm:col-span-1">
                    <label
                      htmlFor="phone"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {phoneNumber}
                    </label>
                    <div className="mt-1">
                      <input
                        onChange={handleOnChange}
                        value={input.phone}
                        required
                        type="text"
                        name="phone"
                        id="phone"
                        autoComplete="tel"
                        className={`block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                          input?.errors?.hasOwnProperty("phone") &&
                          showPhoneError
                            ? "errorInput"
                            : "border-gray-300"
                        }`}
                        onBlur={() => setShowPhoneError(true)}
                        onFocus={() => setShowPhoneError(false)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {nextDisabled ? (
                <button
                  className="w-full rounded-md border border-transparent bg-primary-mono-1  py-2 text-sm font-medium text-white shadow-sm bg-primary-mono-2/70 cursor-not-allowed mt-8 sm:mt-0 sm:order-last  sm:absolute  sm:-bottom-16"
                  onClick={() => {
                    setTooltipBtn(true);
                  }}
                >
                  {translations.next[urlPrefix]}
                </button>
              ) : (
                <button
                  disabled={nextDisabled}
                  className="w-full rounded-md border border-transparent bg-primary-mono-1 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-mono-1 focus:outline-none focus:ring-2 focus:ring-primary-mono-3 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-primary-mono-2/70 disabled:cursor-not-allowed mt-8 sm:mt-0 sm:order-last  sm:absolute sm:right-0 sm:-bottom-16"
                  onClick={() => {
                    handleSubFormPixelStartCheckout();
                    setCurrentStep(1);
                  }}
                >
                  {translations.next[urlPrefix]}
                </button>
              )}
              {tooltipBtn && (
                <div className="absolute bottom-0 right-0 bg-primary-mono-2 text-white p-2 rounded-md shadow-md">
                  <p className="text-sm">
                    {translations.fill_out_form[urlPrefix]}
                  </p>
                </div>
              )}
            </div>
          )}

          {/* Payment */}
          {currentStep === 1 && (
            <div className="pb-8">
              <div>
                <Breadcrumbs
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              </div>
              <h2 className="text-lg font-medium text-gray-900">
                {translations.payment[urlPrefix]}
              </h2>
              <>
                <CardElement
                  id="stripe-card-element"
                  className="credit-card mt-8"
                  options={{
                    hidePostalCode: true, // We'll be sending up the postal ourselves
                    style: {
                      base: {
                        fontSize: "12px",
                        fontFamily: "Montserrat",
                        color: "#094E18",
                        backgroundColor: "white",
                        iconColor: "#094E18",
                        "::placeholder": {
                          fontWeight: 500,
                          fontFamily: "Montserrat",
                          color: "#094E18",
                          fontStyle: "normal",
                          fontSize: "12px",
                        },
                      },
                      invalid: {
                        fontFamily: "Montserrat",
                        color: "#FF5D3A",
                        iconColor: "#FF5D3A",
                      },
                    },
                  }}
                />
                <AcceptTerms
                  acceptedTerms={acceptedTerms}
                  setAcceptedTerms={setAcceptedTerms}
                  input={input}
                  siteName={siteName}
                />
                <div>
                  <button
                    type="submit"
                    onClick={async (e) => {
                      handlePixelAddPaymentInfoStripe();
                      /* Converge Added Payment Info event */
                      {
                        if (window.cvg === "function") {
                          //@ts-ignore to ignore the error that cvg is not defined
                          cvg({
                            method: "track",
                            eventName: "Added Payment Info",
                            properties: {
                              total_price: getTotalPrice(),
                              currency: country.currency,
                              items: [
                                {
                                  product_id: mainProduct.id, // Woocommerce product ID
                                  sku: mainProduct.sku,
                                  name: mainProduct.name,
                                  price:
                                    mainProductPrices?.PRICE[country.currency],
                                  currency: country.currency,
                                  quantity: nrOfMainProducts,
                                },
                              ],
                            },
                          });
                        }
                      }
                      await handleSubmit(e);
                    }}
                    disabled={creditLoading || !acceptedTerms || isCardInvalid}
                    className="flex justify-between w-full rounded-md border border-transparent bg-primary-mono-2 mt-8 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-primary-mono-1 focus:outline-none focus:ring-2 focus:ring-primary-mono-3 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-primary-mono-2/70 disabled:cursor-not-allowed"
                  >
                    <span>{translations.pay[urlPrefix]}</span>
                    <div>
                      {creditLoading && (
                        <Image
                          src={`/carticons/Icon_Loading.png`}
                          className="animate-spin"
                          height={20}
                          width={20}
                          quality={40}
                          alt="Loading icon"
                        />
                      )}
                    </div>
                  </button>
                  {creditcardErrorMessage && (
                    <div className="rounded-md bg-red-50 p-4">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <XCircleIcon
                            className="h-5 w-5 text-red-400"
                            aria-hidden="true"
                          />
                        </div>
                        <div className="ml-3">
                          <h3 className="text-sm font-medium text-red-800">
                            {creditcardErrorMessage}
                          </h3>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            </div>
          )}

          {/* Order summary */}
          <div className="mt-10 hidden lg:block lg:mt-0">
            <Testimonial
              title={reviewText}
              author={reviewAuthor}
              image={urlFor(reviewImage).quality(40).url()}
              rating={reviewRating}
            />
            <h2 className="text-lg font-medium text-gray-900 pt-8">
              {translations.summary_header[urlPrefix]}
            </h2>

            <div className="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <h3 className="sr-only">Items in your cart</h3>
              <ul role="list" className="divide-y divide-gray-200">
                {[strippedProduct].map((product, i) => (
                  <li key={product.id + i} className="flex px-4 py-4 sm:px-6">
                    <div className="flex-shrink-0 relative w-20 h-20">
                      <Image
                        src={mainProduct.images[0].src}
                        alt={mainProduct.images[0].alt || ""}
                        objectFit="cover"
                        layout="fill"
                        quality={40}
                      />
                    </div>

                    <div className="ml-6 flex flex-1 flex-col">
                      <div className="min-w-0 flex-1">
                        <h4 className="text-sm">{mainProduct.name}</h4>
                      </div>

                      <div className="flex items-start justify-end">
                        <div className="ml-4">
                          <label htmlFor="quantity" className="sr-only">
                            Quantity
                          </label>
                          {/* array with nrs from 1 to 10 and map over it */}
                          <select
                            id="quantity"
                            name="quantity"
                            onChange={(e) =>
                              setNrOfMainProducts(parseInt(e.target.value))
                            }
                            className="rounded-md border border-gray-300 text-left text-base font-medium text-gray-700 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
                          >
                            {[1, 2, 3, 4, 5, 6, 7, 8].map((nr) => {
                              return (
                                <option key={nr * 3.14} value={nr}>
                                  {nr}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
              <dl className="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
                <div className="mt-1">
                  <label
                    htmlFor="discount-code-mobile"
                    className="block text-sm font-medium text-gray-700"
                  >
                    {translations.enter_code[urlPrefix]}
                  </label>
                  <div className="mt-1 flex relative">
                    {input?.errors?.hasOwnProperty("coupon") && (
                      <div>
                        <p className="text-sm text-red-500 absolute -bottom-6">
                          {input?.errors?.coupon}
                        </p>
                      </div>
                    )}
                    <input
                      // onChange={(e) => setCode(e.target.value)}
                      ref={codeRef}
                      type="text"
                      name="coupon"
                      id="coupon"
                      className={`mr-4 block w-full rounded-md shadow-sm focus:border-primary-mono-2 focus:ring-primary-mono-2 sm:text-sm ${
                        input?.errors?.hasOwnProperty("coupon")
                          ? "border-bubblegum"
                          : "border-gray-300"
                      }
                      `}
                    />
                    <button
                      onClick={async (e) => {
                        e.preventDefault();
                        await validateCoupon();
                      }}
                      className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-primary-mono-3 focus:ring-offset-2 focus:ring-offset-gray-50"
                    >
                      <span className="flex space-x-2 items-center">
                        {translations.apply[urlPrefix]}
                        {couponLoading && (
                          <AiOutlineLoading3Quarters className="animate-spin inline-block ml-2" />
                        )}
                      </span>
                    </button>
                  </div>
                </div>
                {acceptedCodes.length > 0 && (
                  <div className="flex flex-col justify-center">
                    {acceptedCodes.map((c, i) => (
                      <div
                        key={c.code + i}
                        className="flex items-center justify-between"
                      >
                        <dt className="text-sm">{c.code}</dt>
                        <dd className="text-sm font-medium text-gray-900">
                          {`${
                            country.currency === "NOK" ? "kr" : country.currency
                          } -${discount} ${
                            appconfig.currencySymbols[country.currency]
                          }`}
                        </dd>
                      </div>
                    ))}
                  </div>
                )}
                <div className="flex items-center justify-between">
                  <dt className="text-sm">
                    {translations.subtotal[urlPrefix]}
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {country.currency === "NOK" ? "kr" : country.currency}{" "}
                    {getPartialSum()}
                    {appconfig.currencySymbols[country.currency]}
                  </dd>
                </div>
                {/* <div className="flex items-center justify-between">
                  <dt className="text-sm">
                    {translations.shipping_address[urlPrefix]}
                  </dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {"  "}
                    {country.currency === "NOK"
                      ? "kr"
                      : country.currency} 0{" "}
                    {appconfig.currencySymbols[country.currency]}
                  </dd>
                </div> */}
                <div className="flex items-center justify-between">
                  <dt className="text-sm">{translations.taxes[urlPrefix]}</dt>
                  <dd className="text-sm font-medium text-gray-900">
                    {country.currency === "NOK" ? "kr" : country.currency}{" "}
                    {getTax()} {appconfig.currencySymbols[country.currency]}
                  </dd>
                </div>
                <div className="flex items-center justify-between border-t border-gray-200 pt-6">
                  <dt className="text-base font-medium">
                    {translations.total[urlPrefix]}
                  </dt>
                  <dd className="text-base font-medium text-gray-900">
                    {discount
                      ? (country.currency === "NOK" ? "kr" : country.currency) +
                        " " +
                        getTotalPrice() +
                        ` ${country.currency}`
                      : getTotalPrice() + ` ${country.currency}`}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </form>
        <div className="relative mt-32">
          {/* <p className="my-1 pl-8 text-black font-normal"> */}
          {/* Remove hidden to show */}
          <div className="rounded-2xl overflow-clip transform transition lg:hover:scale-110 hidden">
            <ThirtyDayFreeTrial page={mainProduct.name.toString()} />
          </div>

          <p className="my-2 pl-2 text-black font-normal">
            {translations.subscriptions[urlPrefix]}
          </p>
          <PricingTable />
        </div>
        <Disclosure as="div" className="m-1 overflow-scroll hidden">
          {({ open }) => (
            <>
              <dt className="text-lg ">
                <Disclosure.Button className="flex items-center justify-start text-left text-black space-x-2">
                  <span className="flex h-7 items-center">
                    <ChevronDownIcon
                      className={classNames(
                        open ? "-rotate-180" : "rotate-0",
                        "h-4 w-4 transform"
                      )}
                      aria-hidden="true"
                    />
                  </span>
                  <span className="font-light text-[13px] leading-[37px] text-primary-mono-1">
                    {translations.subscriptions[urlPrefix]}
                  </span>
                </Disclosure.Button>
              </dt>

              <Disclosure.Panel as="dd">
                <div className="relative pt-10 flex flex-col space-y-4 p-1 font-objektiv md:space-y-0 md:space-x-4 md:flex-row">
                  <PricingTable />
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};

const translations = {
  subscriptions: {
    en: "Paid subscription in app required for use (i)",
    no: "Abonnement betales i appen ved bruk",
    se: "Abonnemang betalas i appen vid användning",
    da: "Abonnement betales i appen ved brug",
    fi: "Maksullinen tilaus sovelluksessa vaaditaan käyttöön",
    de: "Bezahltes Abonnement in der App erforderlich für die Nutzung",
    fr: "Abonnement payant dans l'application requis pour l'utilisation",
    es: "Suscripción de pago en la aplicación requerida para su uso",
  },
  shipping: {
    en: "Shipping",
    no: "Frakt",
    se: "Frakt",
    da: "Fragt",
    fi: "Toimitus",
    de: "Versand",
    fr: "Livraison",
    es: "Envío",
  },
  apply: {
    en: "Apply",
    no: "Bruk",
    se: "Använd",
    da: "Anvend",
    fi: "Käytä",
    de: "Anwenden",
    fr: "Appliquer",
    es: "Aplicar",
  },
  summary_header: {
    en: "Summary",
    no: "Oppsummering",
    se: "Sammanfattning",
    da: "Resumé",
    fi: "Yhteenveto",
    de: "Zusammenfassung",
    fr: "Résumé",
    es: "Resumen",
  },
  pay: {
    en: "Pay",
    no: "Betal",
    se: "Betala",
    da: "Betal",
    fi: "Maksa",
    de: "Bezahlen",
    fr: "Payer",
    es: "Pagar",
  },
  vipps: {
    en: "Pay",
    no: "Hurtigkasse",
    se: "Betala",
    da: "Betal",
    fi: "Maksa",
    de: "Bezahlen",
    fr: "Payer",
    es: "Pagar",
  },
  loading_payment_text: {
    no: "Betaling tar noen sekunder. Vennligst ikke forlat denne siden",
    en: "Payment takes a few seconds. Please do not leave this page",
    se: "Betaling tar några sekunder. Vänligen lämna inte denna sida",
    da: "Betalingen tager nogle få sekunder. Venligst forlad ikke denne side.",
    fi: "Maksu kestää muutaman sekunnin. Älä poistu tältä sivulta.",
    de: "Die Zahlung dauert einige Sekunden. Bitte verlassen Sie diese Seite nicht",
    fr: "Le paiement prend quelques secondes. Veuillez ne pas quitter cette page",
    es: "El pago tarda unos segundos. Por favor, no abandone esta página",
  },
  your_details: {
    en: "Your details",
    no: "Kontaktinformasjon",
    se: "Kontaktinformation",
    da: "Kontaktinformation",
    fi: "Tietosi",
    de: "Ihre Daten",
    fr: "Vos coordonnées",
    es: "Tus datos",
  },
  shipping_address: {
    en: "Shipping address",
    no: "Leveringsadresse",
    se: "Leveransadress",
    da: "Leveringsadresse",
    fi: "Toimitusosoite",
    de: "Lieferadresse",
    fr: "Adresse de livraison",
    es: "Dirección de envío",
  },
  next: {
    en: "Next",
    no: "Neste",
    se: "Nästa",
    da: "Næste",
    fi: "Seuraava",
    de: "Nächste",
    fr: "Suivant",
    es: "Siguiente",
  },
  payment: {
    en: "Payment",
    no: "Betaling",
    se: "Betalning",
    da: "Betaling",
    fi: "Maksu",
    de: "Zahlung",
    fr: "Paiement",
    es: "Pago",
  },
  credit_card_payment: {
    en: "Credit card",
    no: "Kredittkort",
    se: "Kreditkort",
    da: "Kreditkort",
    fi: "Luottokortti",
    de: "Kreditkarte",
    fr: "Carte de crédit",
    es: "Tarjeta de crédito",
  },
  req_field: {
    en: "= required field",
    no: "= Obligatorisk felt",
    se: "= Obligatoriskt fält",
    da: "= Påkrævet felt",
    fi: "= Pakollinen kenttä",
    de: "= Pflichtfeld",
    fr: "= Champ obligatoire",
    es: "= Campo obligatorio",
  },
  required_fields: {
    no: "Fyll ut alle obligatoriske felt",
    en: "Please fill out all required fields",
    se: "Vänligen fyll i alla obligatoriska fält",
    da: "Udfyld venligst alle påkrævede felter",
    fi: "Täytä kaikki pakolliset kentät",
    de: "Bitte füllen Sie alle Pflichtfelder aus",
    fr: "Veuillez remplir tous les champs obligatoires",
    es: "Por favor, rellene todos los campos obligatorios",
  },
  accept_terms_first: {
    no: "Godta vilkårene først",
    en: "Please accept terms and conditions first",
    se: "Vänligen acceptera villkor och bestämmelser först",
    da: "Accepter venligst vilkår og betingelser først",
    fi: "Hyväksy ensin ehdot ja ehdot",
    de: "Bitte akzeptieren Sie zuerst die Allgemeinen Geschäftsbedingungen",
    fr: "Veuillez d'abord accepter les termes et conditions",
    es: "Por favor, acepte los términos y condiciones primero",
  },
  vipps_express: {
    en: "Vipps",
    no: "Vipps",
    se: "Vipps",
    da: "Vipps",
    fi: "Vipps",
    de: "Vipps",
    fr: "Vipps",
    es: "Vipps",
  },
  klarna_payment: {
    en: "Klarna",
    no: "Klarna",
    se: "Klarna",
    da: "Klarna",
    fi: "Klarna",
    de: "Klarna",
    fr: "Klarna",
    es: "Klarna",
  },
  subtotal: {
    en: "Subtotal",
    no: "Delsum",
    se: "Delsumma",
    da: "Delsum",
    fi: "Välisumma",
    de: "Zwischensumme",
    fr: "Sous-total",
    es: "Subtotal",
  },
  total: {
    en: "Total",
    no: "Total",
    se: "Total",
    da: "Total",
    fi: "Yhteensä",
    de: "Gesamt",
    fr: "Total",
    es: "Total",
  },
  enter_code: {
    en: "Do you have a discount code?",
    no: "Har du en rabattkode?",
    se: "Har du en rabattkod?",
    da: "Har du en rabatkode?",
    fi: "Onko sinulla alennuskoodi?",
    de: "Haben Sie einen Rabattcode?",
    fr: "Avez-vous un code de réduction?",
    es: "¿Tienes un código de descuento?",
  },
  discounts: {
    en: "Discounts",
    no: "Rabatter",
    se: "Rabatter",
    da: "Rabatter",
    fi: "Alennukset",
    de: "Rabatte",
    fr: "Remises",
    es: "Descuentos",
  },
  taxes: {
    en: "VAT",
    no: "MVA",
    se: "MOMS",
    da: "MOMS",
    fi: "ALV",
    de: "MwSt",
    fr: "TVA",
    es: "IVA",
  },
  subscribe: {
    en: "Subscribe to our newsletter",
    no: "Abonner på vårt nyhetsbrev",
    se: "Prenumerera på vårt nyhetsbrev",
    da: "Abonner på vores nyhedsbrev",
    fi: "Tilaa uutiskirjeemme",
    de: "Abonnieren Sie unseren Newsletter",
    fr: "Abonnez-vous à notre newsletter",
    es: "Suscríbete a nuestro boletín",
  },
  checkout_errors: {
    accept_terms: {
      en: "Terms and conditions needs to be read and accepted to place an order. Please tick the box if you agree.",
      no: "Vilkår og betingelser må leses og godtas for å legge til bestilling. Merk av boksen hvis du er enig.",
      se: "Villkor och bestämmelser måste läsas och godkännas för att lägga en beställning. Markera rutan om du håller med.",
      da: "Vilkår og betingelser skal læses og accepteres for at placere en ordre. Tjek venligst boksen, hvis du er enig.",
      fi: "Ehtoja ja ehtoja on luettava ja hyväksyttävä tilauksen tekemiseksi. Valitse ruutu, jos olet samaa mieltä.",
      de: "Die Allgemeinen Geschäftsbedingungen müssen gelesen und akzeptiert werden, um eine Bestellung aufzugeben. Bitte kreuzen Sie das Kästchen an, wenn Sie einverstanden sind.",
      fr: "Les termes et conditions doivent être lus et acceptés pour passer une commande. Veuillez cocher la case si vous êtes d'accord.",
      es: "Los términos y condiciones deben leerse y aceptarse para realizar un pedido. Marque la casilla si está de acuerdo.",
    },
    must_be: {
      en: "must be",
      no: "må være",
      se: "måste vara",
      da: "skal være",
      fi: "täytyy olla",
      de: "muss sein",
      fr: "doit être",
      es: "debe ser",
    },
    to: {
      en: "to",
      no: "til",
      se: "till",
      da: "til",
      fi: "jotta",
      de: "zu",
      fr: "à",
      es: "a",
    },
    characters: {
      en: "characters",
      no: "tegn",
      se: "tecken",
      da: "tegn",
      fi: "merkkiä",
      de: "Zeichen",
      fr: "caractères",
      es: "caracteres",
    },
    is_not_valid: {
      en: "is not valid",
      no: "er ikke gyldig",
      se: "är inte giltig",
      da: "er ikke gyldig",
      fi: "ei ole kelvollinen",
      de: "ist nicht gültig",
      fr: "n'est pas valide",
      es: "no es válido",
    },
    is_requiered: {
      en: "Please add",
      no: "Vennligst legg til",
      se: "Lägg till",
      da: "Tilføj venligst",
      fi: "Lisää",
      de: "Bitte hinzufügen",
      fr: "Veuillez ajouter",
      es: "Por favor agregue",
    },
    first_name: {
      en: "First name",
      no: "Fornavn",
      se: "Förnamn",
      da: "Fornavn",
      fi: "Etunimi",
      de: "Vorname",
      fr: "Prénom",
      es: "Nombre de pila",
    },
    last_name: {
      en: "Last name",
      no: "Etternavn",
      se: "Efternamn",
      da: "Efternavn",
      fi: "Sukunimi",
      de: "Nachname",
      fr: "Nom de famille",
      es: "Apellido",
    },
    country: {
      en: "Country",
      no: "Land",
      se: "Land",
      da: "Land",
      fi: "Maa",
      de: "Land",
      fr: "Pays",
      es: "País",
    },
    address1: {
      en: "Street address",
      no: "Gateadresse",
      se: "Gatuadress",
      da: "Gadeadresse",
      fi: "Katuosoite",
      de: "Straßenadresse",
      fr: "Adresse de rue",
      es: "Dirección de la calle",
    },
    city: {
      en: "City field",
      no: "By",
      se: "Stad",
      da: "By",
      fi: "Kaupunki",
      de: "Stadt",
      fr: "Ville",
      es: "Ciudad",
    },
    post_code: {
      en: "Post code",
      no: "Postnummer",
      se: "Postnummer",
      da: "Postnummer",
      fi: "Postinumero",
      de: "Postleitzahl",
      fr: "Code postal",
      es: "Código postal",
    },
    phone_number: {
      en: "Phone number",
      no: "Telefonnummeret",
      se: "Telefonnummer",
      da: "Telefonnummer",
      fi: "Puhelinnumero",
      de: "Telefonnummer",
      fr: "Numéro de téléphone",
      es: "Número de teléfono",
    },
    coupon: {
      en: "Coupon",
      no: "Kupong",
      se: "Kupong",
      da: "Kupon",
      fi: "Kuponki",
      de: "Gutschein",
      fr: "Coupon",
    },
    terms_and_conditions: {
      en: "Les og aksepter kjøpsvilkår først",
      no: "Please read and accept terms and conditions first",
      se: "Läs och acceptera köpvillkoren först",
      da: "Læs og accepter købsbetingelserne først",
      fi: "Lue ja hyväksy ensin ostoehtomme",
      de: "Bitte lesen und akzeptieren Sie zuerst die Allgemeinen Geschäftsbedingungen",
      fr: "Veuillez lire et accepter les termes et conditions d'achat d'abord",
      es: "Lea y acepte los términos y condiciones de compra primero",
    },
    could_not_check_coupon: {
      en: "Could not check you coupon, please contact the support team",
      no: "Kunne ikke sjekke kupongen, kontakt kundeservice",
      se: "Kunde inte kontrollera kupongen, kontakta supportteamet",
      da: "Kunne ikke kontrollere kuponen, kontakt supportteamet",
      fi: "Kuponkia ei voitu tarkistaa, ota yhteyttä tukitiimiin",
      de: "Der Gutschein konnte nicht überprüft werden. Bitte kontaktieren Sie das Support-Team",
      fr: "Impossible de vérifier votre coupon, veuillez contacter l'équipe d'assistance",
      es: "No se pudo verificar el cupón, comuníquese con el equipo de soporte",
    },
    coupon_is_used: {
      en: `The coupon is used once before, and can't be used again`,
      no: "Denne kupongen er brukt, og kan ikke gjenbrukes",
      se: "Kupongen används en gång tidigare och kan inte användas igen",
      da: "Kuponen bruges en gang før og kan ikke bruges igen",
      fi: "Kuponkia käytetään kerran aiemmin, eikä sitä voi käyttää uudelleen",
      de: "Der Gutschein wurde bereits einmal verwendet und kann nicht erneut verwendet werden",
      fr: "Le coupon est utilisé une fois auparavant et ne peut pas être réutilisé",
      es: "El cupón se usa una vez antes y no se puede usar nuevamente",
    },
    coupon_does_not_exist: {
      en: "The coupon does not exist",
      no: "Kupongen eksisterer ikke",
      se: "Kupongen finns inte",
      da: "Kuponen eksisterer ikke",
      fi: "Kuponkia ei ole olemassa",
      de: "Der Gutschein existiert nicht",
      fr: "Le coupon n'existe pas",
      es: "El cupón no existe",
    },
  },
  fill_out_form: {
    en: "Please fill out the entire form",
    no: "Vennligst fyll ut hele skjemaet",
    se: "Fyll i hela formuläret",
    da: "Udfyld venligst hele formularen",
    fi: "Täytä koko lomake",
    de: "Bitte füllen Sie das gesamte Formular aus",
    fr: "Veuillez remplir tout le formulaire",
    es: "Por favor complete todo el formulario",
  },
};

export default CheckoutFormv2;
