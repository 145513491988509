import React, { useContext, useState } from "react";
import {
  loadStripe,
  StripeElementLocale,
  StripeElementsOptions,
} from "@stripe/stripe-js";
import { Product } from "../../lib";
import { Elements } from "@stripe/react-stripe-js";
import { CountryContext } from "../../context";
import StripeExpressComponent from "./stripeExpressComponent";
const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY);

export type Coupon = {
  discountType: string;
  code: string;
  valueUSD: string;
  valueEuro: string;
  value: string;
};
interface Props {
  productID: number;
  coupon: Coupon;
  input: any;
  getTotalPrice: Function;
  mainProduct: Product;
  nrMainProducts: number;
}

const StripeExpressCheckout = (props: Props) => {
  const {
    productID,
    coupon,
    input,
    getTotalPrice,
    mainProduct,
    nrMainProducts,
  } = props;
  const countryContext = useContext(CountryContext);

  const priceNumber: number = Number(getTotalPrice()) * 100;

  const options: StripeElementsOptions = {
    mode: "payment" as "payment" | "setup" | "subscription",
    amount: priceNumber,
    currency: countryContext.country.currency.toLowerCase(),
    locale: countryContext.country.stripeLocale as StripeElementLocale, // TODO: This changed the langua in the Link payment, but not Paypabl Check if Paypal handles it by itself
    appearance: {
      variables: {
        borderRadius: "40px",
      },
    },
  };

  if (!priceNumber) return null;
  return (
    <>
      <header>
        <Elements stripe={stripePromise} options={options}>
          <div className="h-fit py-2">
            <StripeExpressComponent
              key={countryContext.country.stripeLocale + "stripeexpress"} // To make sure this rerenders when the country changes
              productID={productID}
              coupon={coupon}
              input={input}
              getTotalPrice={getTotalPrice}
              mainProduct={mainProduct}
              nrMainProducts={nrMainProducts}
            />
          </div>
        </Elements>
      </header>
    </>
  );
};

export default StripeExpressCheckout;
