import GoogleMapReact from "google-map-react";
import Link from "next/link";
import { useState } from "react";
import { BiMap, BiPackage } from "react-icons/bi";
import { GlobalConfig, Store } from "../../lib";
import { useLocale } from "../../translations";
import Marker from "./marker";

const Map = (props: {
  stores: Store[];
  btnText: string;
  header: string;
  config: GlobalConfig;
}) => {
  const { stores, btnText, config, header } = props;
  const urlPrefix = useLocale().urlPrefix;
  const [currentStore, setCurrentStore] = useState<Store | undefined>(
    undefined
  );
  const [clickedStoreId, setClickedStoreId] = useState("");
  const [focusedLocation, setFocusedLocation] = useState<Store | undefined>(
    undefined
  );

  if (!stores || stores.length === 0) {
    console.error("No stores found");
    return <></>;
  }

  const storeUrl = config.mainNavigation.filter(
    (i) => i.groupid === "stores"
  )[0];

  return (
    <div className="my-12 h-fit w-screen flex flex-col justify-center items-center text-center">
      {header && (
        <p className="p-2 text-primary-mono-1 font-p22 font-medium text-center text-xl lg:text-2xl">
          {header}
        </p>
      )}
      <div className="h-80 w-11/12 rounded-3xl rounded-bl-none overflow-hidden">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY,
          }}
          defaultCenter={stores[0].geopoint}
          defaultZoom={6}
        >
          {stores?.map((store, _) => (
            <Marker
              key={store._id}
              lat={store.geopoint.lat}
              lng={store.geopoint.lng}
              showTooltip={
                currentStore?._id === store._id ||
                focusedLocation?._id === store._id
              }
              onClick={() => {
                if (clickedStoreId === store._id) {
                  setClickedStoreId("");
                  setFocusedLocation(undefined);
                  return;
                }
                setFocusedLocation(store);
                setClickedStoreId(store._id);
                // scrollToCard(store);
              }}
              onMouseOver={() => {
                if (clickedStoreId !== store._id) {
                  setClickedStoreId("");
                  setFocusedLocation(undefined);
                }
                setCurrentStore(store);
              }}
              onMouseOut={() => {
                if (clickedStoreId !== store._id) setCurrentStore(undefined);
              }}
              store={store}
              focusedLocation={focusedLocation}
            />
          ))}
        </GoogleMapReact>
      </div>
      {/* TODO fix link to stores for other locales  */}
      <div className="w-screen flex justify-center items-center space-x-2 pt-4">
        {storeUrl && (
          <Link
            as={`/${urlPrefix}/${storeUrl.slug.current}`}
            href="/[lang]/[category]"
          >
            <a className="z-50 bg-primary-mono-2 text-white py-2 px-4 relative rounded-3xl font-objektiv hover:underline transition-transform hover:scale-105 cursor-pointer">
              <span className="flex space-x-1 items-center">
                <BiMap size={20} />
                <span>{btnText}</span>
              </span>
            </a>
          </Link>
        )}
        <Link as={`/${urlPrefix}/distribute`} href="/[lang]/[category]">
          <a className="z-50 bg-primary-mono-2 text-white py-2 px-4 relative rounded-3xl font-objektiv hover:underline transition-transform hover:scale-105 cursor-pointer">
            <span className="flex space-x-1 items-center">
              <BiPackage size={20} />
              <span>{translations.becomeDistributer[urlPrefix]}</span>
            </span>
          </a>
        </Link>
      </div>
    </div>
  );
};

const translations = {
  becomeDistributer: {
    no: "Bli utsalgssted",
    en: "Become a distributor",
    se: "Bli återförsäljare",
    de: "Werden Sie Händler",
    dk: "Bliv forhandler",
    fr: "Devenez distributeur",
    fi: "Tule jälleenmyyjäksi",
    es: "Conviértase en distribuidor",
  },
};

export default Map;
