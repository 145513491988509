import { useEffect, useState } from "react";
import { Image as ImageType } from "../../lib/types";
import Link from "next/link";
import Image from "next/image";
import { getPositionFromHotspot } from "../../lib";
import { useLocale } from "../../translations";
interface Props {
  imageOne: ImageType & { titleText: string; text: string };
  imageTwo: ImageType & { titleText: string; text: string };
  text: string;
  google_icon: ImageType;
  google_text: string;
  google_url: string;
  apple_icon: ImageType;
  apple_text: string;
  apple_url: string;
  urlFor: any;
}
const LocalizedTwoImagesAndText = (props: Props) => {
  const {
    imageOne,
    text,
    google_icon,
    google_text,
    apple_icon,
    apple_text,
    google_url,
    apple_url,
    urlFor,
  } = props;

  const urlPrefix = useLocale().urlPrefix;
  const [isDogOrCat, setIsDogOrCat] = useState("");
  useEffect(() => {
    if (typeof window !== "undefined") {
      setIsDogOrCat(
        window.location.href.includes("lilcat") ||
          window.location.href.includes("localhost:3001")
          ? "Lilcat"
          : "Lildog"
      );
    }
  });
  return (
    <div className="text-primary-1 flex flex-col text-center justify-center items-center font-objektiv pt-2 w-screen">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-p22">
        {isDogOrCat}
        {translations.updatesEveryMinute[urlPrefix]
          ? translations.updatesEveryMinute[urlPrefix]
          : translations.updatesEveryMinute["en"]}
      </h1>
      {text && <p className="text-lg lg:text-2xl font-default">{text}</p>}
      <div className="flex flex-col w-full items-center overflow-x-hidden">
        <div className="w-[400px] sm:w-[6p40x] md:w-[768px] lg:w-[1024] 2xl:w-[1600px] h-[225px] sm:h-[360px] md:h-[432px] lg:h-[576px] 2xl:h-[900px] relative py-2 ">
          <Image
            src={urlFor(imageOne, true).url()}
            layout="fill"
            objectFit="cover"
            alt={imageOne.alt || ""}
            objectPosition={
              imageOne?.hotspot && getPositionFromHotspot(imageOne.hotspot)
            }
          />
        </div>
      </div>

      <div className="grid mb-8 md:mb-16 lg:grid-cols-2">
        <Link href={google_url || "/"}>
          <a className="flex mt-3 lg:mr-6 w-40 h-12 md:w-48 md:h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer">
            {google_icon && (
              <div className="mr-3 h-[28px] w-[28px] md:h-[40px] md:w-[40px] relative py-2 ">
                <Image
                  src={urlFor(google_icon, true).url()}
                  layout="fill"
                  className=""
                  objectFit="contain"
                  alt={google_icon.alt || ""}
                />
              </div>
            )}
            <div>
              <div className="text-xs">{google_text}</div>
              <div className=" text-base md:text-xl font-semibold font-sans -mt-1">
                Google Play
              </div>
            </div>
          </a>
        </Link>
        <Link href={apple_url || "/"}>
          <a className="flex mt-3 w-40 h-12 md:w-48 md:h-14 bg-black text-white rounded-lg items-center justify-center hover:cursor-pointer">
            {apple_icon && (
              <div className="mr-3 relative h-[28px] w-[28px] md:h-[40px] md:w-[40px] justify-start">
                <Image
                  src={urlFor(apple_icon, true).url()}
                  layout="fill"
                  className=" "
                  objectFit="contain"
                  alt={apple_icon.alt || ""}
                />
              </div>
            )}
            <div>
              <div className="text-xs">{apple_text || ""}</div>
              <div className="text-base md:text-2xl font-semibold font-sans -mt-1">
                App Store
              </div>
            </div>
          </a>
        </Link>
      </div>
    </div>
  );
};

const translations = {
  updatesEveryMinute: {
    no: " oppdateres hvert minutt",
    en: " updates every minute",
    se: " uppdateras varje minut",
    dk: " opdateres hvert minut",
    fr: " se met à jour toutes les minutes",
    fi: " päivittyy joka minuutti",
    de: " aktualisiert sich jede Minute",
    es: " se actualiza cada minuto",
  },
};

export default LocalizedTwoImagesAndText;
