import React from "react";
import Vippsbutton from "./Vippsbutton";
import KlarnaExpress from "./KlarnaExpress";
import StripeExpressCheckout from "./checkout/stripeExpressCheckout";

interface Props {
  getTotalPrice: Function;
  product: any;
  coupon: any;
  productTaxRate: any;
  country: any;
  input: any;
  nrMainProducts: number;
}

const FastCheckout = (props: Props) => {
  const {
    getTotalPrice,
    product,
    coupon,
    productTaxRate,
    country,
    nrMainProducts,
  } = props;
  const klarnaInput = {
    country: country.countryCode,
    createAccount: false,
    paymentMethod: "klarna",
    acceptTerms: true,
    subscribeNews: false,
    errors: null,
  };
  const productID = product?.id;
  const couponCode = coupon?.code;

  return (
    <div className="grid grid-cols-1 z-30">
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 ">
        <div className="w-full md:w-1/2  ">
          <Vippsbutton
            productID={productID}
            coupon={couponCode}
            lgScreenWidth={""}
            smScreenWidth={""}
            input={{
              acceptTerms: true,
              subscribeNews: false,
            }}
            getTotalPrice={getTotalPrice}
            mainProduct={product}
          />
        </div>
        <div className="w-full md:w-1/2">
          <KlarnaExpress
            input={klarnaInput}
            getTotalPrice={getTotalPrice}
            coupon={coupon}
            productTaxRate={productTaxRate}
            productID={productID}
            lgScreenWidth={""}
            smScreenWidth={""}
            mainProduct={product}
            country={country}
          />
        </div>
      </div>
      <div className="w-full">
        <StripeExpressCheckout
          productID={productID}
          coupon={coupon}
          input={undefined}
          getTotalPrice={getTotalPrice}
          mainProduct={product}
          nrMainProducts={nrMainProducts}
        />
      </div>
    </div>
  );
};
export default FastCheckout;
