import { useContext, useState } from "react";
import axios from "axios";
import { Product } from "../../lib/types";
import { CountryContext } from "../../context";
import {
  ExpressCheckoutElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  StripeExpressCheckoutElementOptions,
  StripeExpressCheckoutElementShippingAddressChangeEvent,
  ChangeResolveDetails,
  StripeExpressCheckoutElementClickEvent,
  ClickResolveDetails,
  StripeExpressCheckoutElementConfirmEvent,
} from "@stripe/stripe-js";
import { Coupon } from "./stripeExpressCheckout";
import { useLocale } from "../../translations";
import { useSitename } from "../../context/SitenameContext";
interface Props {
  productID: number;
  coupon: Coupon;
  input: any;
  getTotalPrice: Function;
  mainProduct: Product;
  nrMainProducts: number;
}
const countryCodes = [
  "AT", // Østeriket
  "BE", // Belgia
  "DK", // Danmark
  "EE", // Estonia
  "FI", // Finland
  "FR", // Frankriket
  "DE", // Tyskland
  "IS", // Island
  "IL", // Israel
  "IT", // Italia
  "LV", // Latvia
  "LT", // Lithauen
  "LU", // Luxemburg
  "NL", // Nederland
  "NO", // Norge
  "SI", // Slovenia
  "SK", // Slovakia
  "ES", // Spania
  "SE", // Sverige
  "CH", // Sveits
  "GG", // Guernsey
  "JE", // Jersey
  "JP", // Japan
];
const StripeExpressComponent = (props: Props) => {
  const { productID, coupon, nrMainProducts } = props;
  const stripe = useStripe();
  const elements = useElements();
  const countryContext = useContext(CountryContext);
  const locale = useLocale();
  const { siteName } = useSitename();

  const [errorMessage, setErrorMessage] = useState("");

  const createOrder = async (billingCountry: string) => {
    const { data: created_order } = await axios.post(
      "/api/payment/checkout_order",
      {
        products: [
          {
            id: productID,
            quantity: nrMainProducts,
          },
        ],
        order: {
          country: billingCountry,
        },
        coupon: coupon?.code || "",
        payment_method: "stripe_express",
        payment_method_title: "Stripe Express Checkout",
        shipping_method_id:
          countryContext.country.countryCode == "NO" ? "Post_nord" : "DHL",
        shipping_method_title:
          countryContext.country.countryCode == "NO" ? "Post Nord" : "DHL",
        cvg_uid: document?.cookie?.match(/__cvg_uid=([^;]+)/)?.[1] || "",
      }
    );
    return created_order;
  };

  const createPaymentIntent = async (orderId: Number) => {
    const { data: clientSecret } = await axios.post(
      "/api/payment/payment_intent_express",
      {
        created_order: orderId,
      }
    );
    return clientSecret;
  };

  const onShippingAddressChange = async (
    event: StripeExpressCheckoutElementShippingAddressChangeEvent
  ) => {
    // TODO: Use this if shipping Addres is needed to calculate new shipping cost
    // TODO: Call resolve function
    const { address, resolve } = event;
    const test: ChangeResolveDetails = {};
    /* Give DHL as shipping method if country is not Norway */
    // if (address?.country !== "NO") {
    //   test.shippingRates = [
    //     {
    //       id: "dhl",
    //       displayName: "DHL",
    //       amount: 0,
    //       deliveryEstimate: {
    //         maximum: { unit: "day", value: 7 },
    //         minimum: { unit: "day", value: 5 },
    //       },
    //     },
    //   ];
    // } else {
    //   test.shippingRates = [
    //     {
    //       id: "post_nord",
    //       displayName: "Post Nord",
    //       amount: 0,
    //       deliveryEstimate: {
    //         maximum: { unit: "day", value: 7 },
    //         minimum: { unit: "day", value: 5 },
    //       },
    //     },
    //   ];
    // }
    resolve();
  };
  const onClick = async (props: StripeExpressCheckoutElementClickEvent) => {
    const { resolve } = props;
    const options: ClickResolveDetails = {
      // emailRequired: true,
      phoneNumberRequired: true,
      shippingAddressRequired: true,
      allowedShippingCountries: countryCodes,
      shippingRates: [
        {
          id: "free-shipping",
          displayName: translations["free-shipping"][locale.urlPrefix],
          amount: 0,
          deliveryEstimate: {
            maximum: { unit: "day", value: 7 },
            minimum: { unit: "day", value: 5 },
          },
        },
      ],
    };
    resolve(options);
  };

  const onConfirm = async (props: StripeExpressCheckoutElementConfirmEvent) => {
    if (!stripe) {
      console.error("stripe not loaded");
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      console.error("submitError", submitError);
      setErrorMessage(submitError.message);
      return;
    }
    const created_order = await createOrder(countryContext.country.countryCode);

    const clientSecret = await createPaymentIntent(created_order.id);

    let return_url =
      siteName === "lildog" ? "https://lildog.com" : "https://lilcat.com";
    if (process.env.ENVIRONMENT === "DEV") {
      return_url =
        siteName === "lildog"
          ? "https://lildog-git-dev-lildog.vercel.app"
          : "https://lilcat-git-dev-lildog.vercel.app";
    } else if (process.env.ENVIRONMENT === "QA") {
      return_url =
        siteName === "lildog"
          ? "https://lildog-git-qa-lildog.vercel.app"
          : "https://lilcat-git-qa-lildog.vercel.app";
    }
    const { error } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${return_url}/${locale.urlPrefix}/order/${created_order?.id}`,
      },
    });

    if (error) {
      setErrorMessage(error.message);
      return;
    } else {
      // The payment has been processed!
    }
  };

  const onCancel = () => {
    // TODO: Do we need to do anything here? Don't think so as all logic is run after onConfirm
    // Send cancelevent to woo
    console.log("canceled");
  };

  const expressCheckoutOptions: StripeExpressCheckoutElementOptions = {
    buttonHeight: 48,
  };

  return (
    <div>
      <div className="h-fit py-2">
        <ExpressCheckoutElement
          onConfirm={onConfirm}
          onClick={onClick}
          onShippingAddressChange={onShippingAddressChange}
          onCancel={onCancel}
          options={expressCheckoutOptions}
        />
      </div>
    </div>
  );
};

export default StripeExpressComponent;

const translations = {
  "free-shipping": {
    en: "Free shipping",
    no: "Gratis frakt",
    se: "Gratis frakt",
    da: "Gratis fragt",
    fi: "Ilmainen toimitus",
    de: "Kostenloser Versand",
    fr: "Livraison gratuite",
    es: "Envío gratis",
  },
};
