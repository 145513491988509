import React from "react";

interface ScrollButtonProps {
  targetId: string;
  children: React.ReactNode;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({ targetId, children }) => {
  const handleClick = () => {
    const targetElement = document.getElementById(targetId);
    const offset = 100;
    if (targetElement) {
      const screenWidth = window.screen.width;
      const targetPosition = targetElement.getBoundingClientRect().top;
      const offsetPosition =
        targetPosition + window.pageYOffset - (screenWidth > 768 ? offset : 20);

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  return <button onClick={handleClick}>{children}</button>;
};

export default ScrollButton;
